<template>
  <div>
    <!-- <v-btn v-if="checkedSensors && !sensors.length && !isReadOnly" to="/add">Add New Sensor</v-btn> -->
    <v-card>
      <v-card-title>
        <span class="mr-3">Sensors</span><v-btn v-if="!isReadOnly && !sensors.length && checkedSensors && isCustomer" to="/add">Add Sensor</v-btn>
        <v-flex xs4 sm2 v-if="!isCustomer && !isMDX && !isKDX && !isTDX && !isReadOnly">
          <v-select v-model="deviceType" hide-details :items="dashboardItems" class="ml-5 pt-0"></v-select>
        </v-flex>
        <div class="flex-grow-1"></div>
        <v-text-field xs12 color="#012b45" v-model="search" label="Search" single-line hide-details clearable @click:clear="removeSearch"></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="dashboardHeaders"
        :items="deviceType === 'KDX' ? kdxSensors : deviceType === 'TDX' ? tdxSensors : mdxSensors"
        :items-per-page="20"
        item-key="id"
        class="elevation-1"
        :search="search"
        sort-by="name"
        :mobile-breakpoint="0"
        :loading="loading"
      >
        <template v-if="loading" v-slot:loading><span>Loading Sensors...</span></template>
        <template v-else-if="!loading && !sensors.length" v-slot:no-data><span>No Sensors Found</span></template>
        <template v-slot:body="{items}" v-else-if="deviceType === 'KDX'">
          <tbody>
            <template v-for="item in items">
              <tr @click="routeTo(item.uuid, item)" :key="item.id">
                <td>
                  <v-icon medium :color="item.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
                </td>
                <td>
                  <v-text-field
                    hide-details
                    single-line
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.name"
                  ></v-text-field>
                </td>
                <td>{{ item.lastCheckIn ? lastUpdated(item.lastCheckIn) : "NA" }}</td>
                <td>
                  <v-chip
                    :color="item.b1Temp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.b1Temp ? item.b1Temp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.b2Temp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.b2Temp ? item.b2Temp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.b3Temp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.b3Temp ? item.b3Temp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.b4Temp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.b4Temp ? item.b4Temp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.compSuctionTemp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.compSuctionTemp ? item.compSuctionTemp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.compReturnTemp > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.compReturnTemp ? item.compReturnTemp : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip
                    :color="item.ambient > 200 ? 'red': 'green'"
                    label
                    dark
                  >{{ item.ambient ? item.ambient : "NA"}}</v-chip>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
        <template v-slot:body="{items}" v-else-if="deviceType === 'TDX'">
          <tbody>
            <template v-for="(item,i) in items">
              <tr @click="routeTo(item.uuid, item)" :key="item.id">
                <td>
                  <v-icon medium :color="item.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="item.name"
                    @save="editItem(item, 'name', i, $event)"
                    @open="setItem(item)"
                    large
                    color="#012b45"
                  >
                    <v-text-field
                      color="#012b45"
                      v-model="item.name"
                      hide-details                        
                    ></v-text-field>
                    <template v-slot:input>
                      <v-text-field
                        color="#012b45"
                        v-model="item.name"
                        hide-details
                        :readonly="isReadOnly"                    
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="hidden-xs-only">
                  <v-edit-dialog
                    :return-value.sync="item.locationNote"
                    @save="editItem(item, 'locationNote', i, $event)"
                    @open="setItem(item)"
                    large
                    color="#012b45"
                  >
                    <v-text-field
                      color="#012b45"
                      v-model="item.locationNote"
                      hide-details
                    ></v-text-field>
                    <template v-slot:input>
                      <v-text-field
                        color="#012b45"
                        v-model="item.locationNote"
                        hide-details
                        :readonly="isReadOnly"                    
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="hidden-xs-only" v-if="isSuper">
                  <v-chip
                      :color="checkSensorBattery(item.battery)"
                      label
                      dark
                      v-if="item.battery"
                    >{{ item.battery }}V</v-chip>
                    <div v-else></div>
                </td>
                <td v-if="item.low !== null" class="hidden-xs-only">
                  <v-text-field
                    hide-details
                    single-line
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'low', i, $event)"
                    type="number"
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.low"
                    :readonly="isReadOnly"
                  ></v-text-field>
                </td>
                <td v-else @click.stop="item.low = 30" class="hidden-xs-only">Not Set</td>
                <td v-if="item.high !== null" class="hidden-xs-only">
                  <v-text-field
                    hide-details
                    single-line
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'high', i, $event)"
                    type="number"
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.high"
                    :readonly="isReadOnly"
                  ></v-text-field>
                </td>
                <td v-else @click.stop="item.high = 44" class="hidden-xs-only">Not Set</td>
                <!-- <td v-if="item.duration !== null" class="hidden-xs-only">
                  <v-text-field
                    hide-details
                    single-line
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'duration', i, $event)"
                    type="number"
                    min="10"
                    max="60"
                    step="10"
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.duration"
                  ></v-text-field>
                </td>
                <td v-else @click.stop="item.duration = 30" class="hidden-xs-only">Not Set</td> -->
                <template v-if="item.lastCheckIn">
                  <td class="hidden-xs-only">{{ lastUpdated(item.lastCheckIn) }}</td>
                  <!-- <td class="d-flex align-center" v-if="item.displayValues === 2">
                    <v-chip
                      class="mr-1"
                      :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                      label
                      dark
                    >{{ item.currentTemp }}</v-chip>
                    <v-chip
                      :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                      label
                      dark
                    >{{ item.currentTemp2 }}</v-chip>
                  </td>-->
                  <td>
                    <v-chip
                      :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                      label
                      dark
                    >{{ item.currentTemp }}</v-chip>
                  </td>
                  <!-- <td>
                  <v-chip color="green" label dark>{{ item.reading.am }}</v-chip>
                  </td>-->
                </template>
                <template v-else>
                  <td class="text-left" colspan="2">No Readings</td>
                </template>
              </tr>
            </template>
          </tbody>
        </template>
        <template v-slot:body="{items}" v-else-if="deviceType === 'MDX'">
          <tbody>
            <template v-for="(item,i) in items">
              <tr @click="routeTo(item.uuid, item)" :key="item.id">
                <td>
                  <v-icon medium :color="item.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
                </td>
                <td>
                  <v-text-field
                    hide-details
                    single-line
                    :color="item.success ? 'green' : '#012b45'"
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'name', i, $event)"
                    v-model="item.name"
                  ></v-text-field>
                </td>
                <td>{{ item.lastCheckIn ? lastUpdated(item.lastCheckIn) : "NA" }}</td>
                <td>
                  <v-chip color="green" label dark>{{ checkProperty("t1", item) }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ checkProperty("t2", item) }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ checkProperty("t3", item) }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ checkProperty("t4", item) }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p1 ? item.p1 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p2 ? item.p2 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p3 ? item.p3 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p4 ? item.p4 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p5 ? item.p5 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.p6 ? item.p6 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.v1 ? item.v1 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.v2 ? item.v2 : "NA" }}</v-chip>
                </td>
                <td>
                  <v-chip color="green" label dark>{{ item.v3 ? item.v3 : "NA" }}</v-chip>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      currentItem: "",
      deviceType: "TDX",
      checkedSensors: false,
      kdxHeaders: [
        {
          text: "Online",
          value: "online",
          width: "8%",
          sortable: false,
          class: "th-header",
        },
        {
          text: "Name",
          value: "name",
          // align: "start",
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
          class: "hidden-xs-only",
        },
        { text: "B1", value: "b1Temp" },
        { text: "B2", value: "b2Temp" },
        { text: "B3", value: "b3Temp" },
        { text: "B4", value: "b4Temp" },
        { text: "C Suction", value: "compSuctionTemp" },
        { text: "C Return", value: "compReturnTemp" },
        { text: "Ambient", value: "ambient" },
      ],
      mdxHeaders: [
        {
          text: "Online",
          value: "online",
          width: "8%",
          sortable: false,
          class: "th-header",
        },
        {
          text: "Name",
          value: "name",
          // align: "start",
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
          class: "hidden-xs-only",
        },
        { text: "T1", value: "t1" },
        { text: "T2", value: "t2" },
        { text: "T3", value: "t3" },
        { text: "T4", value: "t4" },
        { text: "P1", value: "p1" },
        { text: "P2", value: "p2" },
        { text: "P3", value: "p3" },
        { text: "P4", value: "p4" },
        { text: "P5", value: "p5" },
        { text: "P6", value: "p6" },
        { text: "V1", value: "v1" },
        { text: "V2", value: "v2" },
        { text: "V3", value: "v3" },
      ],
      tdxHeaders: [
        {
          text: "Online",
          value: "online",
          width: "8%",
          sortable: false,
          class: "th-header",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Location",
          value: "locationNote",
          class: "hidden-xs-only",
        },
        {
          text: "Alarm Low (℉)",
          value: "low",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Alarm High (℉)",
          value: "high",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
          width: "20%",
          class: "hidden-xs-only",
        },
        { text: "Temp (℉)", value: "currentTemp", width: "10%" },
        // { text: "Ambient", value: "reading.am" }
      ],
      superHeaders: [
        {
          text: "Online",
          value: "online",
          width: "8%",
          sortable: false,
          class: "th-header",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Location",
          value: "locationNote",
          class: "hidden-xs-only",
        },
        {
          text: "Battery",
          value: "battery",
          class: "hidden-xs-only",
        },
        {
          text: "Alarm Low (℉)",
          value: "low",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Alarm High (℉)",
          value: "high",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
          width: "20%",
          class: "hidden-xs-only",
        },
        { text: "Temp (℉)", value: "currentTemp", width: "10%" },
        // { text: "Ambient", value: "reading.am" }
      ],
      distributorHeaders: [
        {
          text: "Label",
          value: "label",
        },
        {
          text: "Store",
          value: "store.name",
        },
        {
          text: "Location",
          value: "location.name",
        },
        {
          text: "Last Update",
          value: "reading.createdAt",
        },
        { text: "B1", value: "reading.b1Temp" },
        { text: "B2", value: "reading.b2Temp" },
        { text: "B3", value: "reading.b3Temp" },
        { text: "B4", value: "reading.b4Temp" },
        { text: "C Suction", value: "reading.compSuctionTemp" },
        { text: "C Return", value: "reading.compReturnTemp" },
        { text: "Ambient", value: "reading.ambient" },
      ],
      storeHeaders: [
        {
          text: "Label",
          value: "label",
        },
        {
          text: "Store",
          value: "store.name",
        },
        {
          text: "Location",
          value: "location.name",
        },
        {
          text: "Last Update",
          value: "reading.createdAt",
        },
        { text: "B1", value: "reading.b1Temp" },
        { text: "B2", value: "reading.b2Temp" },
        { text: "B3", value: "reading.b3Temp" },
        { text: "B4", value: "reading.b4Temp" },
        { text: "C Suction", value: "reading.compSuctionTemp" },
        { text: "C Return", value: "reading.compReturnTemp" },
        { text: "Ambient", value: "reading.ambient" },
      ],
      userHeaders: [
        {
          text: "Label",
          value: "label",
        },
        {
          text: "Location",
          value: "location.name",
        },
        {
          text: "Last Update",
          value: "reading.createdAt",
        },
        { text: "B1", value: "reading.b1Temp" },
        { text: "B2", value: "reading.b2Temp" },
        { text: "B3", value: "reading.b3Temp" },
        { text: "B4", value: "reading.b4Temp" },
        { text: "C Suction", value: "reading.compSuctionTemp" },
        { text: "C Return", value: "reading.compReturnTemp" },
        { text: "Ambient", value: "reading.ambient" },
      ],
    };
  },
  async created() {
    // if (this.isCustomer && !this.userDetails) {
    //   await this.$store.dispatch("user/getUserDetails", this.userId);
    // }

    if (this.isSuper) {
      await this.$store.dispatch("sensor/allSensors");
    } else if (this.isDistributor) {
      this.$store.dispatch(
        "sensor/getDistributorSensors",
        this.userDistributorName
      );
    } else if (this.isStore) {
      this.$store.dispatch("sensor/getStoreSensors", this.userStoreName);
    } else if (this.isUser) {
      this.$store.dispatch("sensor/getUserSensors", this.userId);
    } else if (this.checkEmail(this.user.attributes.email)) {
      await this.$store.dispatch("sensor/getCustomerSensors", this.userId);
    } else if (this.isCustomer) {
      await this.$store.dispatch("user/getAllUserDevices", this.userId);
      // let sensors = this.sensors;
      // if (!this.sensors.length) {
      //   sensors = this.userDetails.sensors.items;
      // }
      this.$store.commit(
        "sensor/setSensors",
        await this.$store.dispatch("sensor/formatCustomerSensors", this.userDetails.sensors.items)
      );
    }
    else if (this.isLocation) {
      if (!this.userDetails) await this.$store.dispatch("user/getUserDetails", this.user.username)
      this.$store.commit("sensor/setSensors", this.userDetails.location.sensors.items)
    } else if (this.isMDX) {
      this.deviceType = "MDX";
      await this.$store.dispatch(
        "sensor/getDemoSensors",
        "8f1bc3df-ffa3-4d4a-b84d-8616b360707d"
      );
    } else if (this.isKDX) {
      this.deviceType = "KDX";
      await this.$store.dispatch(
        "sensor/getDemoSensors",
        "921f6417-7d77-4860-bc97-f9b0e3959780"
      );
    } else if (this.isTDX) {
      this.deviceType = "TDX";
      await this.$store.dispatch(
        "sensor/getDemoSensors",
        "26b59516-a9fd-43c5-a88d-6fdd973e6911"
      );
    }
    // if (!this.isCustomer) {
    //   this.$store.dispatch("sensor/sensorSubscription");
    //   // this.$store.dispatch("sensor/deleteSensorSubscription");
    //   // this.$store.dispatch("sensor/readingSubscription");
    // }
    if (this.userSearch) this.search = this.userSearch
    this.checkedSensors = true;
    this.$store.dispatch("sensor/sensorSubscription");
  },
  computed: {
    ...mapState("sensor", ["sensors"]),
    ...mapState("user", ["user", "userDetails", "userSearch"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "isUser",
      "isCustomer",
      "isLocation",
      "isAdmin",
      "isMDX",
      "isKDX",
      "isTDX",
      "isFuelCell",
      "userDistributorName",
      "userStoreName",
      "userId",
    ]),
    ...mapGetters("sensor", ["kdxSensors", "mdxSensors", "tdxSensors"]),
    loading() {
      if (!this.checkedSensors) return "#002b45"
      else return false
    },
    isReadOnly() {
      if (this.isLocation || this.isAdmin) return true
      else return false
    },
    dashboardHeaders() {
      if (this.isSuper && this.deviceType === "TDX") return this.superHeaders;
      if (this.deviceType === "MDX") return this.mdxHeaders;
      else if (this.deviceType === "TDX") return this.tdxHeaders;
      else if (this.deviceType === "KDX") return this.kdxHeaders;
      else {
        if (this.isSuper) return this.superHeaders;
        else if (this.isDistributor) return this.distributorHeaders;
        else if (this.isStore) return this.storeHeaders;
        else return this.userHeaders;
      }
    },
    dashboardItems() {
      return this.userDistributorName === "tdxdemo"
        ? ["TDX"]
        : ["TDX", "MDX", "KDX"];
    },
  },
  methods: {
    removeSearch() {
      if (this.userSearch) this.$store.commit("user/removeSearch")
    },
    setItem(item) {
      this.currentItem = { ...item };
    },
    checkProperty(prop, item) {
      if (item[prop] == null) {
        return "NA";
      } else return item[prop];
    },
    checkEmail(email) {
      if (
        email === "dairyqueen@mail.com" ||
        email === "agordonfsf@rrv.net"
      ) {
        return true;
      } else return false;
    },
    async editItem(item, prop, i, event) {
      if (this.isMDX || this.isKDX || this.isTDX) return;
      let result;
      if (item.device.model === "mdx") {
        result = await this.$store.dispatch("sensor/updateSensorProperty", {
          id: item.id,
          prop,
          value: event ? event : item.name,
        });
      } else {
        result = await this.$store.dispatch("sensor/updateSensorProperty", {
          id: item.id,
          prop,
          value: event ? event : prop === "locationNote" ? item.locationNote : item.name,
          duration: item.duration ? item.duration : "30",
          durationChange: prop === "duration" ? true : false,
        });
      }

      if (result) {
        // item.success = true;
        // this.items[i].success = true;
        // item.success = true;
        // setTimeout(() => {
        //   item.success = false;
        // }, 2000);
      } else {
        console.log("error");
      }
    },
    checkSensorRange(val, low, high) {
      if (low === null || high === null) {
        return "black";
      } else if (val >= high || val <= low) {
        return "red";
      } else if (val >= high - 2 || val <= low + 2) {
        return "#d8d300";
      } else return "green";
    },
    checkSensorBattery(val) {
      if (val > 2.75) return "green"
      else if (val > 2.5) return "#d8d300"
      else return "red"
    },
    switchDev(item) {
      this.$store.dispatch("sensor/updateDev", item);
    },
    lastUpdated(date) {
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        return `${new Date(date).toLocaleTimeString()}`
      }
      else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    subscribe(item) {
      this.$store.dispatch("sensor/addUserSub", item.id);
    },
    selectTag(tag) {
      this.search = tag;
    },
    routeTo(route, item) {
      if (this.search) {
        this.$store.commit("user/setSearch", this.search)
      }
      if (item.duel) {
        this.$router.push(`/device/${route}/${item.duelId}`);
      }
      else this.$router.push(`/device/${route}`);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    },
    customFilter(value, search, item) {
      if (typeof value === "object") {
        value = value.map((item) => item.name).join(" ");
      } else if (typeof value === "number") {
        value = value.toString();
      }
      return (
        value != null &&
        search != null &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
  },
  async beforeDestroy() {
    this.$store.dispatch("sensor/unsubscribeSensor");
    // if (!this.isCustomer) {
    //   this.$store.dispatch("sensor/unsubscribeSensor");
    //   // this.$store.dispatch("sensor/emptyAllSensors");
    // }
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
  span {
    color: #000;
  }
}
table {
  .v-text-field {
    border-color: transparent;
    padding-top: 0px;
    margin-top: 0px;
  }
}
// .th-header {
//   display: none;
// }
.v-input__control {
  .v-input__slot:before {
    border-style: solid;
    border-width: thin 0 0 0;
  }
}
</style>